<template>
  <div>
    <b-card text-variant="center" :img-src="require('@/assets/images/banner/banner.svg')" overlay img-alt="card img">
      <b-card-title class="centered pl-5 pr-5">
        <h1 style="font-size: 1.8vw" class="align-self-center mt-5 text-primary font-weight-bolder">
          Masukan Kode Kuesioner
        </h1>
        <validation-observer ref="formStudent">
          <b-form>
            <b-form-group>
              <validation-provider #default="{ errors }" name="code" rules="required">
                <b-form-input id="code" placeholder="Masukan Kode Kuesioner" v-model="code"
                  :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button type="button" variant="primary" @click.prevent="getQuestion"> Isi Kuesioner </b-button>
          </b-form>
        </validation-observer>
      </b-card-title>
    </b-card>

    <c-table-feedback-student :title="title" :items="getItems" :columns="columns" :totalRows="totalRows"
      :perPage="perPage" :currentPage="currentPage" :api="api" @changeCurrentPage="changeCurrentPage"
      @changePerPage="changePerPage"></c-table-feedback-student>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BButton,
  BForm,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import table from "@/libs/table";
import CTableFeedbackStudent from "@/components/CTableFeedbackStudent";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BCardTitle,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    CTableFeedbackStudent,
    ToastificationContent,
    BButton,
    BForm,
  },
  data() {
    return {
      code: null,
      title: "Riwayat Pengisian Kuesioner",
      api: "questionnaire-session/student",
      getCodeApi: "questionnaire-session/questions",
      dir: false,
      items: [],
      totalRows: 0,
      perPage: 10,
      limit: 0,
      orderBy: "createdAt",
      sortBy: "desc",
      currentPage: 1,
      search: "",
      columns: [
        { key: "no", label: "#", sortable: false },
        { key: "course", label: "Nama Blok", sortable: true },
        { key: "lecturer", label: "Dosen", sortable: true },
        { key: "session", label: "Sesi", sortable: true },
      ],
    };
  },
  mounted() {
    const _ = this;
    if (_.$route.params) {
      if (_.$route.params.variant) {
        _.toastNotification(
          _.$route.params.variant,
          "BellIcon",
          _.$route.params.text
        );
      }
    }
  },
  methods: {
    getItems(ctx, callback) {
      table.getItems(ctx, callback, this);
    },
    changeCurrentPage(page) {
      table.changeCurrentPage(this, page);
    },
    changePerPage(perPage) {
      table.changePerPage(this, perPage);
    },
    toastNotification(variant, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Pemberitahuan",
          icon,
          text,
          variant,
        },
      });
    },
    async getQuestion() {
      let data = [];
      await this.$axios.get(this.getCodeApi + '/' + this.code).then((res) => {
        this.$router.push(
          `${this.$route.path}/answer/${this.code}`
        );
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.message,
            icon: "XIcon",
            variant: "danger",
          },
        });

      });
    }
  },
};
</script>
